import React, { useEffect, useState } from 'react';

import './RegisterForm.css';

const validationRules = {
    required: (val) => val !== null && val !== undefined && val !== '',
    phone: (phone) => {
      const re = /^(03\d{2})-\d{7}$/;
      return re.test(String(phone));
    },
    email: (email) => {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
}

class RegisterForm extends React.Component {
    
    constructor() {
        super();
        
        this.formValidationRules = {
          'department': [{rule: validationRules.required, message: 'Department is required'}],
          'fullName': [{rule: validationRules.required, message: 'Full name is required'}],
          'groupMembers': [{rule: validationRules.required, message: 'Group members is required'}],
          'currentSemester': [{rule: validationRules.required, message: 'Current semester is required'}],
          'projectTitle': [{rule: validationRules.required, message: 'Project title is required'}],
          'projectAbstract': [{rule: validationRules.required, message: 'Project abstact is required'}],
          'email': [{rule: validationRules.required, message: 'Email is required'},{rule: validationRules.email, message: 'Email is invalid'}],
          'phone': [{rule: validationRules.phone, message: 'Phone number is invalid'}],
        }
        
        this.fields = ['department', 'fullName', 'groupMembers', 'currentSemester', 'projectTitle', 'projectAbstract', 'email', 'phone'];
        
        this.state = {
          department: {value: '', isTouched: false, isValid: false, errors: []},
          fullName: {value: '', isTouched: false, isValid: false, errors: []},
          groupMembers: {value: '', isTouched: false, isValid: false, errors: []},
          currentSemester: {value: '', isTouched: false, isValid: false, errors: []},
          projectTitle: {value: '', isTouched: false, isValid: false, errors: []},
          projectAbstract: {value: '', isTouched: false, isValid: false, errors: []},
          email: {value: '', isTouched: false, isValid: false, errors: []},
          phone: {value: '', isTouched: false, isValid: false, errors: []},
          progressText: '',
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }
      
    handleFieldChange = e => {
        let newState = {...this.state};
        newState[e.target.name].value = e.target.value;
        this.validateForm(newState);
    }
      
    handleSetTouched = e => {
        let field = {...this.state[e.target.name], isTouched: true};
        this.setState({[e.target.name] : {...field}});
    }
      
    getClassName = fieldName => {
        const field = this.state[fieldName];
        return field.isTouched && !field.isValid ? 'has-error' : '';
    }
      
    validateForm = (newState) => {
        newState = newState || {...this.state};
        this.fields.map(fieldName => {
          let newField = newState[fieldName];
          newField.errors = [];
          newField.isValid = true;
          this.formValidationRules[fieldName].map(vRule => {
            if(!vRule.rule(this.state[fieldName].value)){
              newField.errors.push(vRule.message);
              newField.isValid = false;
            }
            newState[fieldName] = newField;
          })
        })
        this.setState(newState);
    }
      
    componentWillMount() {
        this.validateForm();
    }

    isFormValid = () => {
        console.log(this.state)
        // Check if all fields are valid
        for (const fieldName in this.state) {
            if (fieldName !== 'progressText' && !this.state[fieldName].isValid) {
                return false;
            }
        }
        return true;
    }

    handleSubmit = async (e) => {        
        e.preventDefault();
        e.target.disabled = true;

        this.setState({ progressText: 'Please wait.. Registeration in progress..' });

        // Construct an object to hold form data
        const formData = {
            department: this.state.department.value,
            fullName: this.state.fullName.value,
            groupMembers: this.state.groupMembers.value,
            currentSemester: this.state.currentSemester.value,
            projectTitle: this.state.projectTitle.value,
            projectAbstract: this.state.projectAbstract.value,
            email: this.state.email.value,
            phone: this.state.phone.value
        };

        const fetchData = async () => {
            try {    
                const response = await fetch('https://script.google.com/macros/s/AKfycbxNLy6tSwPdGt5_ngFDQ9bcpUksF01Oif_huqFKbtHo3vUYOx4zV6XqiwEam8RiajmslA/exec', {
                    method: 'POST',
                    headers: {
                        'content-type': 'text/plain;charset=utf-8'
                    },
                    body: JSON.stringify(formData)
                });

                if (!response.ok) {
                    throw new Error('Failed');
                }

                const responseData = await response.json();
                console.log(responseData)
                this.setState({ progressText: 'Your registration is successfull' });
            } catch (error) {
                this.setState({ progressText: 'Registration failed. Please try again later' });
            }
        }
        fetchData();
    }
      
    render() {
        const { department, fullName, groupMembers, currentSemester, projectTitle, projectAbstract, email, phone } = this.state;
        const isFormValid = this.isFormValid();
        return (
            <form>
                <div className="field-group">
                    <label>Department</label>
                    <select
                        className={department.isTouched && !department.isValid ? 'has-error' : ''}
                        name="department"
                        value={department.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                    >
                        <option value="">Select Department</option>
                        <option value="Computer Science">Computer Science</option>
                        <option value="Physics">Physics</option>
                    </select>
                    {department.isTouched && department.errors.length > 0 && department.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div> 
                <div className="field-group">
                    <label>Full Name</label>
                    <input
                        className={fullName.isTouched && !fullName.isValid ? 'has-error' : ''}
                        name="fullName"
                        value={fullName.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                    />
                    {fullName.isTouched && fullName.errors.length > 0 && fullName.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div> 
                <div className="field-group">
                    <label>Group Members</label>
                    <input
                        className={groupMembers.isTouched && !groupMembers.isValid ? 'has-error' : ''}
                        name="groupMembers"
                        value={groupMembers.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                        placeholder="e.g., Ali, Babar or N/A if none exists"
                    />
                    {groupMembers.isTouched && groupMembers.errors.length > 0 && groupMembers.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div> 
                <div className="field-group">
                    <label>Current Semester</label>
                    <input
                        className={currentSemester.isTouched && !currentSemester.isValid ? 'has-error' : ''}
                        name="currentSemester"
                        value={currentSemester.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                    />
                    {currentSemester.isTouched && currentSemester.errors.length > 0 && currentSemester.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div> 
                <div className="field-group">
                    <label>Project Title</label>
                    <input
                        className={projectTitle.isTouched && !projectTitle.isValid ? 'has-error' : ''}
                        name="projectTitle"
                        value={projectTitle.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                    />
                    {projectTitle.isTouched && projectTitle.errors.length > 0 && projectTitle.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div> 
                <div className="field-group">
                    <label>Abstract</label>
                    <textarea
                        className={projectAbstract.isTouched && !projectAbstract.isValid ? 'has-error' : ''}
                        name="projectAbstract"
                        value={projectAbstract.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                        rows={15}
                        placeholder='The abstract must have a succinct overview of the project. Initially, introduce the motivation behind our work in 1-2 sentences. Following this, present a clear problem statement in 2-3 sentences, highlighting the challenges addressed by your project. Subsequently, outline the proposed solution in 2-3 sentences, emphasizing its innovative approach and potential impact. Finally, summarize the results achieved in 1-2 sentences, offering insights into the outcomes.'
                    />
                    {projectAbstract.isTouched && projectAbstract.errors.length > 0 && projectAbstract.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div>
                <div className="field-group">
                    <label>Email</label>
                    <input
                        className={email.isTouched && !email.isValid ? 'has-error' : ''}
                        name="email"
                        value={email.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                        placeholder="e.g., ali@gmail.com"
                    />
                    {email.isTouched && email.errors.length > 0 && email.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div> 
                <div className="field-group">
                    <label>Phone</label>
                    <input
                        className={phone.isTouched && !phone.isValid ? 'has-error' : ''}
                        name="phone"
                        value={phone.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleSetTouched}
                        placeholder="e.g., 03xx-xxxxxxx"
                    />
                    {phone.isTouched && phone.errors.length > 0 && phone.errors.map((err, i) => (<span key={i} className="error-message">{err}</span>))}
                </div>
                <div className="submit-button">
                    <button type="submit" disabled={!isFormValid} onClick={(e) => this.handleSubmit(e)}>Submit</button>
                </div>
                <div>
                    <label id="progress">{this.state.progressText}</label>
                </div>
            </form>
        )
    }
}

export default RegisterForm;